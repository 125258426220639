<template>
  <vs-row vs-w="12">
    <b-card :title="$t('search.instance.details.settingsHeader')" class="w-100">
      <b-card-text>
        <vs-row class="mb-1">
          {{ $t('search.instance.details.settingsDescription') }}
        </vs-row>

        <vs-row>
          <vs-col vs-w="3" vs-type="flex" vs-justify="left" vs-align="center">
            {{ $t('search.instance.details.fields.productsPerPage') }}
          </vs-col>
          <vs-col vs-w="9" vs-type="flex" vs-justify="left" vs-align="center">
            <vs-input-number v-model="productsPerPage" :step="1" :min="1" :max="100" />
          </vs-col>
        </vs-row>

        <vs-row>
          <vs-col vs-w="3" vs-type="flex" vs-justify="left" vs-align="center">
            {{ $t('search.instance.details.fields.popularQueriesLimit') }}
          </vs-col>
          <vs-col vs-w="9" vs-type="flex" vs-justify="left" vs-align="center">
            <vs-input-number v-model="popularQueriesLimit" :step="1" :min="1" :max="100" />
          </vs-col>
        </vs-row>

        <vs-row>
          <vs-col vs-w="3" vs-type="flex" vs-justify="left" vs-align="center">
            {{ $t('search.instance.details.fields.popularQueriesInterval') }}
          </vs-col>
          <vs-col vs-w="9" vs-type="flex" vs-justify="left" vs-align="center">
            <vs-input-number v-model="popularQueriesInterval" :step="1" :min="1" :max="30" />
          </vs-col>
        </vs-row>

        <vs-row>
          <vs-col vs-w="2">
            <b-button variant="primary" @click="save()">
              {{ $t('search.instance.details.save') }}
            </b-button>
          </vs-col>
        </vs-row>
      </b-card-text>
    </b-card>
  </vs-row>
</template>

<script>
import searchInstanceDetails from '@/store/search/searchInstanceDetails'

export default {
  name: 'SettingsInformation',
  computed: {
    productsPerPage: {
      get() {
        return searchInstanceDetails.state.settings.productsPerPage
      },
      set(value) {
        searchInstanceDetails.dispatch('updateProductsPerPage', value)
      },
    },
    popularQueriesLimit: {
      get() {
        return searchInstanceDetails.state.settings.popularQueriesLimit
      },
      set(value) {
        searchInstanceDetails.dispatch('updatePopularQueriesSettingsLimit', value)
      },
    },
    popularQueriesInterval: {
      get() {
        return searchInstanceDetails.state.settings.popularQueriesInterval
      },
      set(value) {
        searchInstanceDetails.dispatch('updatePopularQueriesSettingsInterval', value)
      },
    },
  },
  methods: {
    save() {
      searchInstanceDetails.dispatch('saveSettings', [])
    },
  },
}
</script>

<style scoped>

</style>
