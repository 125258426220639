














import Vue from 'vue'
import MappingInformation from '@/views/components/catalog/catalogInstanceDetails/mappingInformation.vue'
import SettingsInformation from '@/views/components/search/searchInstanceDetails/settingsInformation.vue'
import SearchInstanceDetails from '@/store/search/searchInstanceDetails'

export default Vue.extend({
  name: 'SearchInstanceVue',
  components: {
    MappingInformation,
    SettingsInformation,
  },
  mounted() {
    SearchInstanceDetails.dispatch('getSearchInstanceDetails', this.$route.params.searchId)
  },

})
