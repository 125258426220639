import { render, staticRenderFns } from "./SearchInstance.vue?vue&type=template&id=01f6a878&scoped=true"
import script from "./SearchInstance.vue?vue&type=script&lang=ts"
export * from "./SearchInstance.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01f6a878",
  null
  
)

export default component.exports