import i18n from '@/libs/i18n'
import Vue from 'vue'
import Vuex from 'vuex'
import { PopularQueriesSettings, SearchApiRequest } from '@/store/search/searchApiRequestHelper'
import CatalogInstanceDetailsStore from '@/store/catalog/catalogInstanceDetails'
import Notifications from '@/store/notifications/notifications'

Vue.use(Vuex)

const getInitialState = (): any => ({
  settings: {
    productsPerPage: 24,
    popularQueriesLimit: 10,
    popularQueriesInterval: 30,
  },
  searchInstanceId: null,
})

export default new Vuex.Store({
  state: getInitialState(),
  mutations: {
    setSearchInstanceId(state: any, id: string) {
      state.searchInstanceId = id
    },
    setProductsPerPage(state: any, productsPerPage: number) {
      state.settings.productsPerPage = productsPerPage
    },
    setPopularQueriesLimit(state: any, limit: number) {
      state.settings.popularQueriesLimit = limit
    },
    setQueriesSettingsInterval(state: any, interval: number) {
      state.settings.popularQueriesInterval = interval
    },
    setPopularQueriesSettings(state: any, popularQueriesSettings: PopularQueriesSettings) {
      state.settings.popularQueriesLimit = popularQueriesSettings.limit
      state.settings.popularQueriesInterval = popularQueriesSettings.interval
    },
  },
  actions: {
    updateProductsPerPage(context: any, productsPerPage: number) {
      context.commit('setProductsPerPage', productsPerPage)
    },
    updatePopularQueriesSettingsLimit(context: any, limit: number) {
      context.commit('setPopularQueriesLimit', limit)
    },
    updatePopularQueriesSettingsInterval(context: any, interval: number) {
      context.commit('setQueriesSettingsInterval', interval)
    },
    getCatalogInstanceSettings(context: any, searchId: string) {
      SearchApiRequest.getPaginationSettings(searchId).then(results => {
        context.commit('setProductsPerPage', results.data.productsPerPage)
      })
    },
    getPopularQueriesSettings(context: any, searchId: string) {
      SearchApiRequest.getPopularQueriesSettings(searchId).then(results => {
        context.commit('setPopularQueriesSettings', results.data)
      })
    },
    saveSettings(context: any) {
      SearchApiRequest.setPaginationSettings(context.state.searchInstanceId, context.state.settings.productsPerPage).then(() => {
        SearchApiRequest.setPopularQueriesSettings(
          context.state.searchInstanceId,
          context.state.settings.popularQueriesLimit,
          context.state.settings.popularQueriesInterval,
        ).then(() => {
          Notifications.commit('addPopupInfo', {
            name: i18n.t('search.blockedPhrase.insertSuccessfulNotificationTitle'),
            message: i18n.t('search.blockedPhrase.insertSuccessfulNotification'),
          }, { root: true })
        })
      }).catch(error => {
        Notifications.commit('addPopupError', error, { root: true })
      })
    },
    getSearchInstanceDetails(context: any, searchId: string) {
      context.commit('setSearchInstanceId', searchId)
      context.dispatch('getCatalogInstanceSettings', searchId)
      context.dispatch('getPopularQueriesSettings', searchId)
      SearchApiRequest.getSearchInstances().then(response => {
        response.data.instances.forEach(instance => {
          if (instance.instanceId === searchId) {
            CatalogInstanceDetailsStore.dispatch('getCatalogInstanceDetails', instance.catalogId)
          }
        })
      })
    },
  },
})
